<template>
  <div class="confirm-email">
    <div class="form-box">
      <div class="title">{{ $t('confirmemail.title') }}</div>
      <div v-if="status && status != undefined && status.length > 0">
        {{ status }}
      </div>
      <div v-else>
        <div v-if="valid && valid != undefined">
          {{ valid }}
        </div>
        <form id="sign-in-form" @submit.prevent="onSubmit">
          <b-field>
            <b-input name="userId" type="hidden" v-model="userId" />
          </b-field>
          <b-field>
            <b-input name="confirmEmailToken" type="hidden" v-model="confirmEmailToken" />
          </b-field>
          <div>
            <input
              type="submit"
              class="button is-primary is-rounded"
              value="Confirm Registration"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script type="es6">
import { KMS_BACKEND } from '@/config'
import axios from 'axios'
import { ToastProgrammatic as Toast } from 'buefy'

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

function notifyMessage(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-success'
  })
}

export default {
  data: () => ({
    isDebug: true,
    userId: 0,
    scope: '',
    confirmEmailToken: '',
    status: '',
    valid: ''
  }),

  metaInfo: {
    title: 'Confirm Homeowner Registration'
  },

  watch: {

  },

  computed: {
  },

  mounted() {
    this.confirmEmailToken = this.$route.query.t
    this.userId = this.$route.query.u

    if(!this.confirmEmailToken || this.confirmEmailToken == undefined || !this.userId || this.userId == undefined)
    {
      this.status = 'Invalid Registration Confirmation Parameters'
    }
    else
    {
      this.valid = 'Valid Registration'
    }
  },

  methods: {
  async onSubmit() {
      if(!this.confirmEmailToken || this.confirmEmailToken == undefined || !this.userId || this.userId == undefined)
      {
        notifyError(`There was a problem reading your registration confirmation.`)
        return
      }

      const payload = {
        userID: this.userId,
        scope: 'homeowner',
        confirmEmailToken: this.confirmEmailToken
      }

      try {
        if(this.isDebug == true) console.debug('onSubmit payload=' + JSON.stringify(payload))

        const path = `${KMS_BACKEND}/User/ConfirmEmail`
        const result = await axios.post(path, payload)

        if(this.isDebug==true) console.debug('result=' + JSON.stringify(result))

        if (result) {
          notifyMessage('Your new registration has been confirmed.')
          this.$router.push('/signin')
        }
        else
        {
          notifyError('There was a problem processing your registration confirmation.')
        }
      } catch(e)
      {
        notifyError(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-box {
  max-width: 480px;
}

.confirm-email {
  display: flex;
  height: 100%;
  min-height: 75vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
